<template>
  <el-alert type="warning">
    你访问的路径不存在，请使用正常方式访问！
    <br />page 404
    <el-link href="/">跳转至首页</el-link>
  </el-alert>
</template>

<script>
export default {};
</script>

<style>
</style>